import React from 'react'
import PropTypes from 'prop-types'
import { trackGa } from '../GoogleTracking'

import './site-info-card.component.scss'

const SiteInfoCardComponent = props => {
  const shouldShowModal = (event, url, tracking) => {
    if (props.showModal) {
      event.preventDefault()
      props.showModal(url, tracking)
    }
  }

  const extSiteUrl = props.siteInfoContent.specificUrl ? (
    props.siteInfoContent.specificUrl
  ) : (
    `https://${props.siteInfoContent.url}`
  )
  const tracking = props.siteInfoContent.tracking
  const phoneTracking = props.siteInfoContent.phoneTracking
  const phoneTracking2 = props.siteInfoContent.phoneTracking2
  const emailTracking = props.siteInfoContent.emailTracking

  return (
    <div className={props.siteInfoContent.visibility ? `site-info-card-new ${props.siteInfoContent.visibility}` : 'site-info-card-new'}>
      <div className="primary-card">
        <div className="card-info-container">
          <p className="site-url">
            <a
              onClick={event => {
                shouldShowModal(event, extSiteUrl, props.siteInfoContent.tracking)
                trackGa(props.siteInfoContent.tracking)
              }}
              href={extSiteUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.siteInfoContent.url}
            </a>
          </p>
          <p className="site-name">{props.siteInfoContent.name}</p>
          <p className="site-address">
            {props.siteInfoContent.address.map((item, i) => {
              return (
                <span className="site-address-line" key={i}>
                  {item}
                </span>
              )
            })}
          </p>
          <p className="site-phone">
            {props.siteInfoContent.phone.map((item, i) => {
              return (
                <span className="site-phone-line" key={i}>
                  {item}
                </span>
              )
            })}
          </p>
          {props.siteInfoContent.email && (
            <p className="site-email">
              Email:{' '}
              <a
                href={`mailto:${props.siteInfoContent.email}`}
                onClick={() => {
                  if (emailTracking) {
                    trackGa(emailTracking)
                  }
                }}
              >
                {props.siteInfoContent.email}
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

SiteInfoCardComponent.propTypes = {
  siteInfoContent: PropTypes.object.isRequired,
  showModal: PropTypes.func
}

export default SiteInfoCardComponent
