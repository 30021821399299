import React, { Component } from 'react'
import Layout from '@components/layout/Layout'
import LeavingSiteModal from '@components/leaving-site-modal/leaving-site-modal.component'
import SiteInfoCardComponent from '@components/site-info-card/site-info-card.component'
import ReadNext from '@components/read-next-button/read-next-button'
import { trackGa } from '@components/GoogleTracking'

import './information-links.scss'


const siteInfos = [
  {
    url: 'www.aasm.org',
    specificUrl: 'https://aasm.org/',
    name: 'American Academy of Sleep Medicine',
    address: ['2510 North Frontage Road', 'Darien, IL 60561'],
    phone: [(
      <span key={1}>Phone: <a href="tel: (630) 737-9700"
        onClick={() => {
          trackGa('key organizations, tap, american academy of sleep medicine phone')
        }}>(630) 737-9700</a>
      </span>), (
      <span key={2}>Fax: <a href="tel: (630) 737-9790"
        onClick={() => {
          trackGa('key organizations, tap, american academy of sleep medicine fax')
        }}>(630) 737-9790</a>
      </span>)],
    tracking: 'key organizations, click, american academy of sleep medicine site',
  },
  {
    url: 'www.wakeupnarcolepsy.org',
    name: 'Wake Up Narcolepsy Inc.',
    email: ' info@wakeupnarcolepsy.org',
    address: ['PO Box 60293', 'Worcester, MA 01606'],
    phone: [(
      <span key={1}>Phone: <a href="tel: (978) 751-3693"
        onClick={() => {
          trackGa('key organizations, tap, wake up narcolepsy phone')
        }}>(978) 751-DOZE (3693)</a>
      </span>)],
    tracking: 'key organizations, click, wake up narcolepsy site',
    emailTracking: 'key organizations, click, wake up narcolepsy email'
  },
  {
    url: 'www.narcolepsynetwork.org',
    specificUrl: 'https://narcolepsynetwork.org/',
    name: 'Narcolepsy Network',
    email: ' NarNet@narcolepsynetwork.org',
    address: ['PO Box 2178', 'Lynnwood, WA 98036'],
    phone: [(
      <span key={1}>Phone: <a href="tel: (401) 667-2523"
        onClick={() => {
          trackGa('key organizations, tap, narcolepsy network phone')
        }}>(401) 667-2523 or</a>
      </span>), (
      <span key={2}>Toll-free: <a href="tel: (888) 292-6522"
        onClick={() => {
          trackGa('key organizations, tap, narcolepsy network toll free')
        }}>(888) 292-6522</a>
      </span>), (
      <span key={3}>Fax: <a href="tel: (401) 633-6567"
        onClick={() => {
          trackGa('key organizations, tap, narcolepsy network fax')
        }}>(401) 633-6567</a>
      </span>)],
    tracking: 'key organizations, click, narcolepsy network site',
    emailTracking: 'key organizations, click, narcolepsy network email'
  },
  {
    url: 'www.project-sleep.com',
    specificUrl: 'https://project-sleep.com/',
    name: 'Project Sleep',
    email: 'info@project-sleep.com',
    address: ['PO Box 70206', 'Los Angeles, CA 90070'],
    phone: [(
      <span key={1}>Phone: <a href="tel: (323) 577-8923"
        onClick={() => {
          trackGa('key organizations, tap, proect sleep phone')
        }}>(323) 577-8923</a>
      </span>)],
    tracking: 'key organizations, click, project sleep site',
    emailTracking: 'key organizations, click, project sleep email'
  },
  {
    url: 'www.ninds.nih.gov',
    name: 'National Institute of Neurological Disorders and Stroke',
    address: ['PO Box 5801', 'Bethesda, MD 20824'],
    phone: [(
      <span key={1}>Voice: <a href="tel: (800) 352-9424"
        onClick={() => {
          trackGa('key organizations, tap, national institute of neurological disorders and stroke phone 1a')
        }}>(800) 352-9424</a> or <a href="tel: (301) 496-5751"
        onClick={() => {
          trackGa('key organizations, tap, national institute of neurological disorders and stroke phone 1b')
        }}>(301) 496-5751</a>
      </span>), (
      <span key={2}>TTY (for people using adaptive equipment): <a href="tel: (301) 468-5981"
        onClick={() => {
          trackGa('key organizations, tap, national institute of neurological disorders and stroke phone 2')
        }}>(301) 468-5981</a>
      </span>)],
    tracking: 'key organizations, click, national institute of neurological disorders and stroke site',
  },
  {
    url: 'www.rarediseases.org',
    specificUrl: 'https://rarediseases.org/',
    name: 'NORD (National Organization for Rare Disorders)',
    address: ['55 Kenosia Avenue', 'PO Box 1968', 'Danbury, CT 06813-1968'],
    phone: [(
      <span key={1}>Phone: <a href="tel: (800) 999-6673"
        onClick={() => {
          trackGa('key organizations, tap, national organization for rare disorders phone')
        }}>(800) 999-6673</a>
      </span>)],
    tracking: 'key organizations, click, national organization for rare disorders site',
  },
  {
    url: 'www.sleepfoundation.org',
    name: 'National Sleep Foundation',
    email: 'nsf@sleepfoundation.org',
    address: ['1010 North Glebe Road','Suite 310', 'Arlington, VA 22201'],
    phone: [(
      <span key={1}>Phone: <a href="tel: (703) 243-1697"
        onClick={() => {
          trackGa('key organizations, tap, national sleep foundation phone')
        }}>(703) 243-1697</a>
      </span>)],
    tracking: 'key organizations, click, national sleep foundation site',
    emailTracking: 'key organizations, click, national sleep foundation email'
  },
  {
    url: 'https://www.pwn4pwn.org',
    specificUrl: 'https://www.pwn4pwn.org/',
    name: 'PWN4PWN',
    email: 'Jane@pwn4pwn.org',
    address: ['700 S Harbour Island Blvd','Suite 403', 'Tampa, FL 33602'],
    phone: [(
      <span key={1}>Phone: <a href="tel: (813) 546-1728"
        onClick={() => {
          trackGa('key organizations, tap, PWN4PWN phone')
        }}>(813) 546-1728</a>
      </span>)],
    tracking: 'key organizations, click, PWN4PWN site',
    emailTracking: 'key organizations, click, PWN4PWN email'

  },
  {
    url: '',
    name: '',
    address: [],
    phone: [(
      <span key={1}><a href=""
        onClick={() => {
          trackGa('')
        }}></a><a href=""
        onClick={() => {
          trackGa('')
        }}></a>
      </span>), (
      <span key={2}><a href=""
        onClick={() => {
          trackGa('')
        }}></a>
      </span>)],
    tracking: '',
    visibility: 'invisible'
  }
]

const childOrgs = [
  {
    url: 'www.aacap.org',
    name: 'The American Academy of Child and Adolescent Psychiatry',
    address: ['3615 Wisconsin Ave NW', 'Washington, DC 20016-3007'],
    phone: [(
      <span key={1}>Phone: <a href="tel: (202) 966-7300"
        onClick={() => {
          trackGa('key organizations, tap, AACAP phone')
        }}>(202) 966-7300</a>
      </span>), (
      <span key={2}>Fax: <a href="tel: (202) 464-0131"
        onClick={() => {
          trackGa('key organizations, tap, AACAP fax')
        }}>(202) 464-0131</a>
      </span>)],
    tracking: 'key organizations, click, AACAP website',
  },
  {
    url: 'www.aap.org',
    specificUrl: 'https://www.aap.org',
    name: 'American Academy of Pediatrics',
    address: ['345 Park Blvd', 'Itasca, IL 60143'],
    phone: [(
      <span key={1}>Phone: <a href="tel: (630) 626-6000"
        onClick={() => {
          trackGa('key organizations, tap, AAP phone')
        }}>(630) 626-6000</a>
      </span>), (
      <span key={2}>Toll Free: <a href="tel: (800) 433-9016"
        onClick={() => {
          trackGa('key organizations, tap, AAP toll free')
        }}>(800) 433-9016</a>
      </span>)],
    tracking: 'key organizations, click, AAP website',
  },
  {
    url: 'www.adolescent-psychiatry.org',
    name: 'American Society for Adolescent Psychiatry',
    email: 'info@adolescent-psychiatry.org',
    address: ['PO Box 3948', 'Parker, CO 80134'],
    phone: [(
      <span key={1}>Phone: <a href="tel: (866) 672-9060"
        onClick={() => {
          trackGa('key organizations, tap, American Society for Adolescent Psychiatry phone')
        }}>(866) 672-9060</a>
      </span>), (
      <span key={2}>Fax: <a href="tel: (720) 496-4974"
        onClick={() => {
          trackGa('key organizations, tap, American Society for Adolescent Psychiatry fax')
        }}>(720) 496-4974</a>
      </span>)],
    tracking: 'key organizations, click, American Society for Adolescent Psychiatry website',
    emailTracking: 'key organizations, click, American Society for Adolescent Psychiatry email'
  },
  {
    url: 'www.nasn.org',
    specificUrl: 'https://www.nasn.org/home',
    name: 'National Association of School Nurses',
    email: 'info@project-sleep.com',
    address: ['1100 Wayne Ave', 'Suite 925', 'Silver Spring, MD 20910-5669'],
    phone: [(
      <span key={1}>Phone: <a href="tel: (240) 821-1130"
        onClick={() => {
          trackGa('key organizations, tap, National Association of School Nurses phone')
        }}>(240) 821-1130</a>
      </span>), (
      <span key={2}>Toll Free: <a href="tel: (866) 627-6767"
        onClick={() => {
          trackGa('key organizations, tap, National Association of School Nurses toll free')
        }}>(866) 627-6767</a>
      </span>), (
      <span key={3}>Fax: <a href="tel: (301) 585-1791"
        onClick={() => {
          trackGa('key organizations, tap, National Association of School Nurses fax')
        }}>(301) 585-1791</a>
      </span>)],
    tracking: 'key organizations, click, National Association of School Nurses website',
    emailTracking: 'key organizations, click, National Association of School Nurses email'
  },
  {
    url: 'www.schoolcounselor.org',
    name: 'American School Counselor Association',
    email: 'asca@schoolcounselor.org',
    address: ['1101 King St', 'Suite 310', 'Alexandria, VA 22314'],
    phone: [(
      <span key={1}>Phone: <a href="tel: (703) 683-ASCA"
        onClick={() => {
          trackGa('key organizations, tap, American School Counselor Association phone')
        }}>(703) 683-ASCA</a>
      </span>)],
    tracking: 'key organizations, click, American School Counselor Association website',
    emailTracking: 'key organizations, click, American School Counselor Association email'
  },
  {
    url: '',
    name: '',
    address: [],
    phone: [(
      <span key={1}><a href=""
        onClick={() => {
          trackGa('')
        }}></a><a href=""
        onClick={() => {
          trackGa('')
        }}></a>
      </span>), (
      <span key={2}><a href=""
        onClick={() => {
          trackGa('')
        }}></a>
      </span>)],
    tracking: '',
    visibility: 'invisible'
  }
]

const readNext = {
  readNextContent: 'Find a sleep specialist',
  goTo: '/stay-connected/locate-a-sleep-specialist/',
  tracking: 'sleep organizations,	click,	find a sleep specialist - sleep orgs'
}

class InformationLinks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLeavingSiteModal: false,
      href: '',
      tracking: '',
      continueModal: '',
      cancelModal: '',
    }

    this.handleHideModal = this.handleHideModal.bind(this)
    this.handleShowModalClicked = this.handleShowModalClicked.bind(this)
  }

  handleHideModal() {
    this.setState({
      showLeavingSiteModal: false
    })
  }

  handleShowModalClicked(url, tracking) {
    this.setState({
      showLeavingSiteModal: true,
      href: url,
      tracking: tracking,
      continueModal: `${tracking} continue`,
      cancelModal: `${tracking} cancel`
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <div className="website-key-organization">
          <div className="primary-container">
            <h1 className="main-page-header">Sleep Organizations</h1>
            <p className="page-comment">
              The third-party resources cited below are for the reader&apos;s information only. Jazz Pharmaceuticals does not
              endorse and is not responsible for the content included in these resources.
            </p>
          </div>
          <div className="card-container-wrapper">
            <div className="primary-container">
              <div className="card-container adult-cards">
                {siteInfos.map((siteInfo, i) => (
                  <SiteInfoCardComponent key={i} siteInfoContent={siteInfo} showModal={this.handleShowModalClicked} />
                ))}
              </div>
              <h3 className="child-container-title">Child/Adolescent Organizations:</h3>
              <div className="card-container">
                {childOrgs.map((childOrg, i) => (
                  <SiteInfoCardComponent key={i} siteInfoContent={childOrg} showModal={this.handleShowModalClicked} />
                ))}
              </div>
              <ReadNext readNext={readNext}/>
            </div>
          </div>
          <LeavingSiteModal
            isShowModal={this.state.showLeavingSiteModal}
            onClose={this.handleHideModal}
            href={this.state.href}
            tracking={this.state.continueModal}
            cancelTracking={this.state.cancelModal}
            location={this.props.location}
          />
        </div>
      </Layout>
    )
  }
}

export default InformationLinks
